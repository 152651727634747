import TextArea from "antd/es/input/TextArea";

export default function ContactInput({
  label,
  id,
  req,
  labelClass,
  type,
  className,
  style,
  placeHolder,
  onChange,
  error,
  errMsg,
  textArea,
  value,
  maxLength
}) {
  return (
    <div className={`${className} form-group`} style={style}>
      <label
        htmlFor={id}
        className={`text-start d-block fw-bold ${labelClass}`}
      >
        {label}
        {req && <span className="text-danger">*</span>}
      </label>
      {type && !textArea && (
        <input
          id={id}
          type={type}
          className="form-control"
          name={id}
          placeholder={placeHolder}
          value={value}
          onChange={onChange}
          aria-required="true"
          aria-invalid="true"
          maxLength={maxLength}
        />
      )}
      {!type && !textArea && (
        <input
          id={id}
          type="text"
          name={id}
          className="form-control"
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
          aria-required="true"
          aria-invalid="true"
          maxLength={maxLength}

        />
      )}
      {textArea && (
        <textarea
          id={id}
          name={id}
          placeholder={placeHolder}
          className="form-control"
          onChange={onChange}
          value={value}
          aria-required="true"
          aria-invalid="true"></textarea>

      )}
      {error && (
        <span
          className="text-danger d-block text-start "
          style={{ fontSize: ".85rem" }}
        >
          {errMsg}
        </span>
      )}
    </div>
  );
}
