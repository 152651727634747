import { Dialog } from "primereact/dialog";
import "./Dialog.css";

export default function DialogModel({
  header,
  className,
  show,
  styles,
  children,
  onHide,
}) {
  const displayBasic = true;
  return (
    <>
      <Dialog
        header={header}
        className={`dialog-container ${className}`}
        visible={show}
        style={styles}
        onHide={onHide}
      >
        {children}
      </Dialog>
    </>
  );
}
