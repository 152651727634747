import { useLocation, useNavigate } from "react-router-dom";
import BodyContainer from "../../../../view/Containers/BodyContainer";
import LayoutContainer from "../../../../view/Containers/LayoutContainer/LayoutContainer";
import RoomCard from "./RoomCard/RoomCard";
import { useEffect, useState } from "react";
import Button from "../../../../view/Button/Btn/Button";

export default function RommDetails({list}) {
    
  const [roomList, setRoomList] = useState(list);

  return (
    <>
    {/* // <BodyContainer> */}
      {/* <section className="container d-block">
        <div className="text-end">
          <Button className="primaryBtn" onClick={()=>navigate('/dxf/report')}>Back to View</Button>
        </div>
        <LayoutContainer download={false} heading="Rooms Details"> */}
        <div className="text-center">

          <span className="bgPrimary text-white px-3 rounded py-1 fs-5 fw-bold">
            Rooms Details
          </span>
        </div>
          <div className="container d-block m-4 px-5 mx-auto">
            {roomList &&
              Object.keys(roomList).map((roomKey, i) => (
                <RoomCard key={roomKey} data={roomList[roomKey]} count={i} />
              ))}
          </div>
        {/* </LayoutContainer>
      </section> */}
    {/* </BodyContainer> */}
    </>
  );
}
