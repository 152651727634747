import { useEffect, useState,useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import AnotherIcon from '../../../assets/icons/duplicate.svg';
import "./ProcessingReport.css";
import BodyContainer from "../../../view/Containers/BodyContainer";
import { dxfFileResultOptimize } from "../../../utlis/dataOptimize/dxfFileResultOptimize";
import LayoutContainer from "../../../view/Containers/LayoutContainer/LayoutContainer";
import LayoutTopContainer from "./LayoutTopContainer/LayoutTopContainer";
import LayoutImg from "../../../assets/img/layout.png";
import GeneralData from "./GeneralData/GeneralData";
import LayoutCardContainer from "./LayoutCardContainer/LayoutCardContainer";
import Button from "../../../view/Button/Btn/Button";
import { useLocation, useNavigate } from "react-router-dom";
import PDFView from "./PDFView/PDFView";
import RommDetails from "./RoomDetails/RoomDetails";
import { Page, Text, View, Document, StyleSheet, Image,PDFViewer,PDFDownloadLink } from '@react-pdf/renderer';
import generatePDF from "react-to-pdf";
import DialogModel from "../../../view/Dialog/Dialog";
import WarnIcon from "../../../assets/icons/warn-icon.svg";


export default function ProcessingReport() {
  const [tabs, setTabs] = useState([]);
  const [list, setList] = useState();
  const [img,setImg]= useState();
  const navigate = useNavigate();
  const location = useLocation();
  const targetRef = useRef();
  console.log(location);
  const [aknErr, setAknErr] = useState(false);
  const handleCancel = () => {
    setAknErr(false);
    navigate("/")

  };
  useEffect(()=>{
    const result =location?.state?.data;
    setImg(location?.state?.img)
    if(result){
      const dataList =dxfFileResultOptimize([...result])
      if(dataList.length>0){
        setList(dataList);

      }else{
        setAknErr(true);
        setTimeout(()=>{
          handleCancel()
        },5000)

      }

    }
    console.log(list)

  },[location.state])
  
  console.log("list", list);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
 

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
  };
  const [pdfView,setPdfView]=useState()
  const pdfGenerate=()=>{
    setPdfView(true);
    console.log(pdfView);
    setTimeout(()=>{
      generatePDF(targetRef, { filename: "civil_planner.pdf" })
       setPdfView(false);

    },500)
  }
  return (
    <BodyContainer>
      <div className="container d-block">
        <div className="text-end">
          <Button className="primaryBtn my-2 flex-row-reverse" innerClass='flex-row-reverse' img={AnotherIcon} imgStyle={{width:'1.05rem'}} onClick={()=>{navigate('/')}}>Analyze Another File</Button>
        </div>
       {list &&<TabView
          activeIndex={activeTabIndex}
          onTabChange={(e) => handleTabChange(e.index)}
        >
          {list.map((item, index) => (
            <TabPanel key={index} header={`Layout - ${index + 1}`}>
              {/* Content of each tab */}
              <LayoutContainer heading={`${item.layoutName} - ${index + 1}`} btnAction={pdfGenerate} >
                <LayoutTopContainer>
                  <GeneralData data={item} image={img} />
                </LayoutTopContainer>
                <LayoutCardContainer data={item} />
                <hr />
                <RommDetails list={item.roomDimensions} />
              </LayoutContainer>
             
                <div ref={targetRef} >
                  {pdfView&&<PDFView list={item} img={img}/>}
                </div>
              
              
            </TabPanel>
          ))}
        </TabView>}

      </div>
      <DialogModel
        className="warning-dialog"
        show={aknErr}
        onHide={handleCancel}
      >
        <div className="d-flex flex-gap-half align-items-center">
          <img src={WarnIcon} alt="warn icon"/>

          <div>
            <span className="fs-5 mb-2 d-block fw-bold">Warning Message</span>

            <p>Layout Not found Provide valid file</p>
          </div>
        </div>
      </DialogModel>

    </BodyContainer>
  );
}
