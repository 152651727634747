import './AboutCard.css';
import Report from '../../../assets/icons/report.png';
import { TbReportSearch } from "react-icons/tb";

export default function AboutCard({ ...props }) {
    return (
        <>
            <div className="card shadow p-3 px-4 col-md-12 col-lg-6 bg-light about-card" >

                <div className="img-container">
                    <img src={props.props.image}/>
                   
                </div>
                <span className='title fs-5 mt-5 fw-bold text-center my-2 '>
                    {props.props.title}
                </span>
                <p className='text-justify'>
                    {props.props.content}
                </p>

            </div>

        </>
    )
}