import AboutImage from '../assets/img/about.jpg';
import AboutCard from '../view/Card/AboutCard/AboutCard';
import { GrIntegration } from "react-icons/gr";
import aboutIcon1 from '../assets/icons/about-1.svg'
import aboutIcon2 from '../assets/icons/about-2.svg'
import aboutIcon3 from '../assets/icons/about-3.svg'
import aboutIcon4 from '../assets/icons/about-4.svg'
import aboutIcon5 from '../assets/icons/about-5.svg'
export default function About({ id }) {
    const list = [
        {
            image: aboutIcon1,
            title: 'Seamless Integration with DXF Files',
            content: 'Civil Planner seamlessly integrates with DXF drawings. By scanning the DXF files, it extracts critical information about building components, including windows, doors, columns, and more. Gone are the days of manual measurements and tedious data entry. With a few clicks, engineers can initiate the estimation process.'
        },
        {
            image: aboutIcon2,
            title: 'Deriving Work Items',
            content: "Our tool goes beyond basic measurements. It intelligently derives work items directly from the DXF drawing. Whether it's the quantity of steel beams, square footage of flooring, or the number of electrical outlets, Civil Planner identifies and categorizes each element. This automation significantly reduces human error and accelerates the estimation workflow."
        },
        {
            image: aboutIcon3,
            title: 'Detailed and Abstract Estimate Reports',
            content: "Civil Planner doesn't stop at raw numbers. It generates comprehensive reports tailored to different stakeholders. For project managers, detailed estimates break down costs by material type, labor, and overhead. Meanwhile, abstract reports provide high-level summaries for executive decision-makers. These reports empower informed choices and foster collaboration across teams."
        },
        {
            image: aboutIcon4,
            title: "Rate and Specification Flexibility",
            content: "Every project is unique, and so are the rates and specifications. Civil Planner allows users to customize these parameters. Need to adjust labor rates based on regional variations? No problem. Want to incorporate eco-friendly materials? Easily done. Our tool adapts to your project's specific requirements, ensuring accuracy and relevance."
        },
        {
            image: aboutIcon5,
            title: "Adding New Works",
            content: "Construction projects evolve. New tasks emerge, unforeseen challenges arise, and scope changes occur. Civil Planner remains agile. Engineers can effortlessly add new work items to the existing estimate. Whether it's an additional room, an upgraded facade, or specialized equipment, our tool accommodates these modifications without disrupting the entire estimation process."
        }
    ]
    return (
        <div className="mt-5">
            <h4 className="fw-bold fs-2 text-center text-black">
                About Civil Planner
                {/* <span className="text-black"> </span> */}
            </h4>
            <div className='about-main'>

                <div className="row my-5 about-main-container align-items-center  p-5 mx-auto " >


                    <div className="col-md-6">
                        <p className='text-justify lh-lg'>
                            &nbsp;&nbsp; In the dynamic world of construction and engineering, accurate material estimation is paramount. Project success hinges on precise cost predictions, efficient resource allocation, and timely execution. Our cutting-edge tool, Civil Planner, stands at the forefront of this revolution. Let's delve into its unique features and how it transforms the estimation landscape.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={AboutImage} className='w-100 rounded shadow-lg' alt='Estimate AI, building estimation, construction ' />
                    </div>

                </div>
            </div>
            <div className='row about-list-container align-items-stretch justify-content-center justify-content-md-evenly flex-gap-1 px-md-5 px-4 mx-auto'>
                {list.map((item) => (
                    <AboutCard props={item} />
                ))}
            </div>

        </div>
    )
}