/* Text only validation */
export function textOnly(val){
    const pattern = /^[a-zA-Z\s]*$/; // Allow only letters and spaces

    // Check if the input value matches the pattern
    const isValidInput = pattern.test(val);
    return isValidInput
};
/* Mobile number validation */
export function mobileValidation(value){    
    const isValidFormat = /^\d{10}$/.test(value);
    // Check if the starting number is larger than 5
    const startsWithValidNumber = /^[6-9]/.test(value);

    // Check if there are no repeating 4 numbers
    const hasNoRepeatingNumbers = !/(.)\1\1\1/.test(value);
    // Check if the input contains only digits, starts with a number larger than 5,
    // has no repeating 4 numbers, and has a length of 10
    const isValidInput = isValidFormat && startsWithValidNumber && hasNoRepeatingNumbers;

    return isValidInput
}

export function emailValidation(value){
    const pattern =/^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the input value matches the pattern
    const isValidInput = pattern.test(value);

    return isValidInput

}

export function nameValidation(val){
    const pattern =/^[a-zA-Z ]{4,}$/;
    const isValidInput = pattern.test(val.trim());
    return isValidInput

}