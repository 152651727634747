import './ItemCard.css';
import ArrowIcon from '../../../../../assets/icons/arrow.svg';
import { useNavigate } from 'react-router-dom';
export default function ItemCard({list,rooms}){
    console.log(list);
    const navigate = useNavigate()
    return(
        
                <div
                  className="card  p-3 py-4 col-lg-3 item-card my-2 mx-auto"

                  
                >
                  <span
                    className="p-3 rounded-circle  text-left"
                    
                  >
                    <img
                      src={list.img}
                      alt="bed Room icon"
                      style={{ width: "3rem" }}
                      className='text-left'
                    />
                  </span>
                  <div className="my-2 ">{list.lableName}</div>
                  <div className=" fs-1">{list.count}</div>
                  {/* {list.lableName ==='Number of Doors'&&
                  <button className="room-link d-flex justify-content-between cursor-pointer border-0 align-items-center" onClick={()=>navigate('/dxf/report/rooms',{state:rooms.roomDimensions})} >
                    View
                    <img src={ArrowIcon} />
                  </button>} */}
                </div>

                
    )

}