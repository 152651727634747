import Button from "../view/Button/Btn/Button";
import BodyContainer from "../view/Containers/BodyContainer";
import "./Contact.css";
import ContactImg from "../assets/contact/quick-enquiry.svg";
import ContactInput from "../view/Input/ContactInput/ContactInput";
import { useEffect, useState } from "react";
import TextCaptcha from "../view/Catcha/TextCaptcha";
import {
  textOnly,
  mobileValidation,
  emailValidation,
  nameValidation
} from "../utlis/validation";
import Subscription from "./Subscription/Subscription";
import SuccessDialog from "../view/Dialog/SuccessDialog/SuccessDialog";
import ErrorDialog from "../view/Dialog/ErrorDialog/ErrorDialog";
export default function Contact() {
  const [name, setName] = useState();
  const [nameValid, setNameValid] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyValid, setCompanyValid] = useState(true);
  const [designation, setDesignation] = useState();
  const [designationValid, setDesignationValid] = useState(true);
  const [contact, setContact] = useState();
  const [contactValid, setContactValid] = useState();
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState();
  const [comments, setComments] = useState();
  const [commentsValid, setCommentsValid] = useState(true);
  const [captchErr, setCaptchaErr] = useState();

  /* CAPTCHA */
  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState();
  const [submit, setSubmit] = useState("");
  const [loading, setLoading] = useState(false);
  const [sucDialogInfo, setSucDialogInfo] = useState({
    show:false,
    content:''
  });
  const [errorDInfo, setErrorDInfo] = useState({
    show:false,
    content:''
  })
  const regenerateCaptcha = () => {
    // Regenerate the captcha
    setCaptcha(generateCaptchaText());
  };

  const generateCaptchaText = () => {
    // Generate and return a new captcha text
    // This logic should match the logic used in the CaptchaText component
    const length = 5; // Or use the same length prop you passed to CaptchaText
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captchaText = "";
    for (let i = 0; i < length; i++) {
      captchaText += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setCaptcha(captchaText);
    return captchaText;
  };
  const resetForm = () => {
    setName("");
    setContact("");
    setEmail("");
    setCompanyName("");
    setDesignation("");
    setComments("");
    setEnteredCaptcha("");
    setCaptchaErr();
    regenerateCaptcha();
  };
  const validateCaptcha = (input) => {
    // Validate the entered captcha against the generated one
    setCaptchaErr(input === captcha);
    return input === captcha;
  };

  useEffect(() => {
    generateCaptchaText();
    if (enteredCaptcha) {
      validateCaptcha(enteredCaptcha);
    }
  }, []);
  const dialogCancel = () => {
    setSucDialogInfo({
      ...sucDialogInfo,
      show: false,
      content: "",
    });
  };
  const errorDCancel=()=>{
    setErrorDInfo({
      ...errorDInfo,
      show: false,
      content: "",
    }
    )
  }
  const submitHandler = async (e) => {
    setSubmit(true);
    e.preventDefault();

    // if()

    if (
      nameValid &&
      emailValid &&
      contactValid &&
      companyValid &&
      designationValid &&
      commentsValid
      // &&captchErr
      // &&enteredCaptcha
    ) {
      setLoading(true);
      const formData = {
        name:name.trim(),
        contact,
        email,
        company_name: companyName.trim(),
        designation:designation.trim(),
        comments:comments.trim(),
      };

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/form/contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        setSubmit(false);
        setLoading(false);
        resetForm();
        setSucDialogInfo({
          show:true,
          content:"Submitted Successfully"
        });
        setTimeout(dialogCancel,5000)
        
      } else {
        setSubmit(false);
        setLoading(false);
        resetForm();
        setErrorDInfo({
          show:true,
          content:data.message
        });
        setTimeout(errorDCancel,5000)

      }
    } else {
      return;
    }
  };
  
  return (
    <BodyContainer>
      <div className="contact content" id="contact">
        <div className="container text-center d-block">
          <h3 className="heading mb-4 text-center">
            Let's talk about everything!
          </h3>

          <div className="row justify-content-center align-items-center flex-md-row">
            <div className="col-md-6">
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p> */}
              <p>
                <img src={ContactImg} alt="Image" className="img-fluid" />
              </p>
            </div>
            <div className="col-md-6">
              <form className="mb-5" onSubmit={submitHandler}>
                <div className="row">
                  {/* Name */}
                  <ContactInput
                    label="Name"
                    id="name"
                    className="col-md-6"
                    req={true}
                    type="text"
                    value={name}
                    placeHolder="Enter your Name"
                    error={(name && !nameValid) || (submit && !name)}
                    errMsg="Input must be at least 4 characters"
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameValid(nameValidation(e.target.value));
                    }}
                  />
                  {/* Contact */}
                  <ContactInput
                    label="Contact"
                    id="contactNumber"
                    className="col-md-6"
                    req={true}
                    type="text"
                    value={contact}
                    placeHolder="Enter your Contact Number"
                    error={(contact && !contactValid) || (submit && !contact)}
                    errMsg="Provide Valid Contact Number"
                    maxLength={10}
                    onChange={(e) => {
                      setContact(e.target.value.trim());
                      setContactValid(mobileValidation(e.target.value.trim()));
                    }}
                  />
                  {/* Email */}
                  <ContactInput
                    label="Email"
                    id="email"
                    className="col-md-12"
                    req={true}
                    type="email"
                    value={email}
                    placeHolder="Enter your Email Id"
                    error={(email && !emailValid) || (!email && submit)}
                    errMsg="Provide Valid Email Id"
                    onChange={(e) => {
                      setEmail(e.target.value.trim());
                      setEmailValid(emailValidation(e.target.value));
                    }}
                  />
                  {/* Company Name */}
                  <ContactInput
                    label="Company Name"
                    id="companyName"
                    className="col-md-6 "
                    req={false}
                    type="text"
                    placeHolder="Enter your Company Name"
                    error={false}
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                  {/* Designation */}
                  <ContactInput
                    label="Designation"
                    id="designation"
                    className="col-md-6 "
                    req={false}
                    type="text"
                    placeHolder="Enter your Designation"
                    error={textOnly(designation) === false}
                    value={designation}
                    onChange={(e) => {
                      setDesignation(e.target.value);
                    }}
                  />

                  <ContactInput
                    label="Comments"
                    id="comments"
                    className="col-md-12 "
                    req={false}
                    placeHolder="Comments..."
                    error={false}
                    errMsg="Provide Valid Email Id"
                    textArea={true}
                    value={comments}
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="row ">
                  <div className="col-6 row">
                    <TextCaptcha val={captcha} captchaReload={generateCaptchaText}/>

                  </div>
                    <ContactInput
                     label="Enter Captcha"
                     id="captcha"
                     className="col-md-6 "
                     req={false}
                     type="text"
                     placeHolder="Enter image Value"
                     error={captchErr===false ||(submit&&!enteredCaptcha)}
                     value={enteredCaptcha}
                     errMsg="Provide Valid Captcha"
                     onChange={(e)=>{
                      setEnteredCaptcha(e.target.value);
                      validateCaptcha(e.target.value)

                     }}
                    
                    />

                   
                </div> */}

                <div className="row">
                  <div className="col-12">
                    <Button
                      className="primaryBtn"
                      type="submit"
                      disabled={loading}
                    >
                      <div className="d-flex align-items-center flex-gap-half">
                        Submit
                        {loading && (
                          <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "1rem" }}
                          ></i>
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
              </form>
              <div id="form-message-warning mt-4"></div>
              <div id="form-message-success">
                Your message was sent, thank you!
              </div>
            </div>
          </div>
        </div>
      </div>

      <Subscription />
      <SuccessDialog  
      show={sucDialogInfo.show} 
      content={sucDialogInfo.content} 
      onHide={dialogCancel}
      />
      <ErrorDialog  
      show={errorDInfo.show} 
      content={errorDInfo.content} 
      onHide={errorDCancel}/>
    </BodyContainer>
  );
}
