import DialogModel from "../Dialog";
import SuccesIcon from '../../../assets/icons/success-icon.svg'

export default function SuccessDialog({show,content,onHide}){
    return(
        <DialogModel 
        show={show}
        onHide={onHide}
        className='success-dialog'
        styles={{width:'400px'}}
        >
            <div className="d-flex flex-gap-1">
                <img src={SuccesIcon} style={{width:'70px'}} />
                <div>
                    <div className="fw-bold fs-5 my-2">Success Message</div>
                    <p>{content}</p>
                </div>

            </div>

        </DialogModel>
    )
}