import Button from "../../Button/Btn/Button";
import DownloadIcon from '../../../assets/icons/download.svg';
// import DownloadIcon from '../../../assets/icons/download.svg';

export default function LayoutContainer({ heading, btnAction, children,download=true }) {
  return (
    <>
      <div className="card my-3">
        <div className="card-header bgPrimary text-white d-flex justify-content-between align-items-center">
          <span>{heading}</span>
         {download&& <Button
            className="primaryBtn"
            style={{ border: "1px solid #fff" }}
            img={DownloadIcon}
            onClick={btnAction}
          >
            Pdf Download
          </Button>}
        </div>
        <div className="card-body p-0">{children}</div>
      </div>
    </>
  );
}
