import React from "react";
import axios from 'axios';
 
export async function getIp  () {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    // setIP(res.data.ip);
    return res.data.ip
  };

