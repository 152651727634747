import LayoutImg from "../../../../assets/img/layout.png";
import "./GeneralData.css";
import { kgToTon } from "../../../../utlis/calculation";
export default function GeneralData({ data, image }) {
  return (
    <div className="p-md-5">
      <div className="p-3 position-relatives d-flex dimension-container">
        

        <img
          src={`${process.env.REACT_APP_SERVER_URL}/image/${image}`}
          className="shadow-lg layoutImg img-fluid bg-white p-2"
         
        />
        <div className="  w-100">

          <div className="bgSecondary  p-4 py-3 dimension-content">
          <span className="fs-4 dimension-head1 primary-text fw-bold">
            Overall Dimension
          </span>
          <div className="px-4">

            <div className="mb-2 block">
              <span className="title">Length </span>
              <span>:</span>
              <span className="result">
                {Number(data.overallDimensions.length).toFixed(2)} feet
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title">Width </span>
              <span>:</span>
              <span className="result">
                {Number(data.overallDimensions.width).toFixed(2)} feet
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title">Area </span>
              <span>:</span>
              <span className="result">
                {Number(data.overallDimensions.area).toFixed(2)} sq.feet
              </span>
            </div>
          </div>
            <hr />
          <span className="fs-4 dimension-head1  primary-text fw-bold">
            Material Calcualtion
          </span>
          
          <div className="bgSecondary my-md-2 p-4 py-1 dimension-content">
          <span className="fs-5 primary-text fw-bold d-block pb-2">Wall</span>

            <div className="mb-2 block">
              <span className="title">Wall Length </span>
              <span>:</span>
              <span className="result">
                {Number(data.calculation.totalWallLength).toFixed(2)} feet
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title">Bricks </span>
              <span>:</span>
              <span className="result">
                {Number(data.calculation.totalBricksRequired).toFixed(0)} 
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title text-nowrap">Cement  </span>
              <span>:</span>
              <span className="result">
                {Number(Math.ceil(data.calculation.cementRequiredInBags)).toFixed(0)} Bags
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title">Sand </span>
              <span>:</span>
              <span className="result">
                {Math.round(kgToTon(data.calculation.sandRequiredInKg))} tons
              </span>
            </div>
          </div>
            <div className="bgSecondary my-md-2 p-4 py-1 dimension-content">
            <span className="fs-5 primary-text fw-bold d-block pb-2">Floor</span>
            
            <div className="mb-2 block">
              <span className="title text-nowrap">Cement  </span>
              <span>:</span>
              <span className="result">
                {Number(Math.ceil(data.calculation.flooring.cementRequiredInBag))} Bags
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title">Sand  </span>
              <span>:</span>
              <span className="result">
                {Number(Math.round(kgToTon(data.calculation.flooring.sandRequiredInKg)))} tons
              </span>
            </div>
            <div className="mb-2 block">
              <span className="title">Tiles {"(1'x1')"}  </span>
              <span>:</span>
              <span className="result">
                {Number(Math.ceil(data.calculation.flooring.tilesReq)).toFixed(0)}
              </span>
            </div>
           
          </div>
          </div>
        </div>
      </div>
      <div className="mx-auto px-2 d-block  notes-content">
      <span ><span className="text-danger">*</span> <strong>Note:</strong> These measurements provide an approximate overview of the project's dimensions and material requirements, but actual measurements may vary during the construction process.</span>
      </div>
    </div>
  );
}
