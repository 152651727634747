import "./PDFView.css";
import Logo from "../../../../assets/pdf-logo.svg";
import CallIcon from "../../../../assets/icons/call.svg";
import MailIcon from "../../../../assets/icons/mail.svg";
import { useRef } from "react";
import generatePDF from "react-to-pdf";
import { kgToTon } from "../../../../utlis/calculation";

export default function PDFView({ list, img }) {
  const targetRef = useRef();
  
  console.log(list);
  // const generatePdfWithOptions = () => {
  //   const options = {
  //     filename: "civilPlanner_planningReport.pdf",
  //     footer: {
  //       content: `<div style="text-align: center; font-size: 10px;"><span>Page sdfkskfdj</span></div>`,
  //     },
  //   };
  //   console.log(targetRef);
  //   generatePDF(targetRef, options);
  // };

  return (
    <>
      {/* <button onClick={generatePdfWithOptions}>Download PDF</button> */}
      <div className="pdf-page" ref={targetRef}>
        <span className="waterMark waterMark1">
          Civil Planner
        </span>
        <span className="waterMark waterMark2">
          Civil Planner
        </span>
         <span className="waterMark waterMark3">
          Civil Planner
        </span>
        <section className="headContainer d-flex justify-content-between align-items-center">
          <span className="name">
            Building Layout Information -
            <span className="secondary-text layoutName">
              {" "}
              {list.layoutName}
            </span>
          </span>
          <div className="right">
            <div className="logo-block">
              <img src={Logo} className="logo img-fluid" />
            </div>
            {/* <h3>Civil Planner</h3> */}
          </div>
        </section>
        <section className="body-container p-3">
          <div className="row text-white p-3">
            <div className="col-4 mb-3">
              <span className="fs-5 secondary-text mb-3 d-block">
                Overall Dimensions
              </span>
              <div>
                <div className="row flex-row flex-nowrap">
                  <div className="col-3 label">Length</div>
                  <div className="col-9 content">
                    : {Number(list.overallDimensions.length).toFixed(2)} feet
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 label">Width</div>
                  <div className="col-9 content">
                    : {Number(list.overallDimensions.width).toFixed(2)} feet
                  </div>
                </div>
                <div className="row">
                  <div className="col-3 label">Area</div>
                  <div className="col-9 content">
                    : {Number(list.overallDimensions.area).toFixed(2)} feet
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8 mb-3">
            <span className="fs-5 secondary-text mb-3 d-block">
                Material Calulation
              </span>
              <div className="row">

            <div className="col-6">
            <span className="fs-5 secondary-text mb-1 d-block">
                Wall
              </span>
            
              <div>
                <div className="row flex-row flex-nowrap">
                  <div className="col-4 label text-nowrap">Wall Length</div>
                  <div className="col-8 content">
                    : {Number(list.calculation.totalWallLength).toFixed(2)} feet
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 label">Bricks</div>
                  <div className="col-8 content">
                    : {Number(Math.ceil(list.calculation.totalBricksRequired))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 label">Cement</div>
                  <div className="col-8 content">
                    : {Number(Math.ceil(list.calculation.cementRequiredInBags))} Bags
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 label">Sand</div>
                  <div className="col-8 content">
                    : {Number(Math.ceil(kgToTon(list.calculation.sandRequiredInKg)))} tons
                  </div>
                </div>
              </div>

            </div>
            <div className="col-6">
            <span className="fs-5 secondary-text mb-1 d-block">
                Floor
              </span>
              <div>
                <div className="row flex-row flex-nowrap">
                  <div className="col-4 label">Cement</div>
                  <div className="col-8 content">
                    : {Number(list.calculation.flooring.cementRequiredInBag).toFixed(2)} Kg
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 label">Sand</div>
                  <div className="col-8 content">
                    : {Number(Math.round(kgToTon(list.calculation.flooring.sandRequiredInKg)))} tons
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 label">Tiles {"(1'x1')"}</div>
                  <div className="col-8 content">
                    : {Number(Math.ceil(list.calculation.flooring.tilesReq))} 
                  </div>
                </div>
              </div>

            </div>
              </div>

            </div>
            <div className="col-12 py-2 pt-4 " style={{borderTop:"1px solid #FFF"}}>
              
             

              <div className="row">
                {list.items.map((item, i) => (
                  <div className="col-3 " key={i}>
                    <div className="row mb-3 align-items-center">
                      <div className="col-8 label">{item.lableName}</div>
                      <div className="col-4 count">
                        :<span>{item.count}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="roomContainer my-3">
            <div className="room-head">Room Details</div>
            <table className="room-table table">
              <thead className="">
                <tr>
                  <th>Room Name</th>
                  <th>Dimensions (Width x Length)</th>
                  <th>Area</th>
                  <th>Image</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(list.roomDimensions).map((roomKey, i) => (
                  // <RoomCard key={roomKey} data={roomList[roomKey]} count={i} />
                  <tr>
                    <td>Room {i + 1}</td>
                    <td>
                      {list.roomDimensions[roomKey].width.toFixed(2)} feet x{" "}
                      {list.roomDimensions[roomKey].length.toFixed(2)} feet
                    </td>
                    <td>{list.roomDimensions[roomKey].area.toFixed(2)} sq. feet</td>
                    
                    <td className="image-container" style={{width:'180px'}}>
                      <img
                        src={`${process.env.REACT_APP_SERVER_URL}/image/${list.roomDimensions[roomKey].image}`}
                        alt="room dimension"
                        className="img-fluid"

                      />
                    </td>
                  </tr>
                ))}
               
              </tbody>
            </table>
          </div>
        </section>
        <section className="pdf-footer p-3 pb-4 ">
          <span className="primary-text fw-bold">Terms & Conditions :</span>
          <div className="row">
            <div className="col-8">
              It's essential to consult with legal experts to ensure that the
              terms and conditions are comprehensive, legally enforceable, and
              tailored to the specific requirements of the AI Building project.
            </div>
            <div className="col-4 row">
              <div className="contact col-6">
                <img
                  src={CallIcon}
                  alt="call"
                  className="me-1"
                  style={{ width: "1.75rem" }}
                />
                <span className="">+91 84381 99081</span>
              </div>
              <div className="mail col-6">
                <img
                  src={MailIcon}
                  alt="call"
                  className="me-1"
                  style={{ width: "1.75rem" }}
                />
                <span className="">info@proz.in</span>
              </div>
            </div>
          </div>
        </section>
        <div className="section-with-page-break px-3 mt-5 text-center">
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/image/${img}`}
            alt="layout overall dimension "
            className="whole-img"
          />
        </div>
      </div>
    </>
  );
}
