import About from "./About";
import Contact from "./Contact";
import UploadForm from "./DXFFileProcess/UploadForm";

export default function Home(){
    return(
        <div >
         <div id="home">
            <UploadForm />
          </div>
          <div id='about'>
            <About />
          </div>
          
          <div id="contact">
            <Contact />
          </div>

        </div>
    )
}