import ItemCard from "./ItemCard/ItemCard"

export default function LayoutCardContainer({data}){
    console.log(data)
    return(
        <>
        <div className="row my-4 justify-content-evenly">

        {data.items.map((item,i)=>(
            <ItemCard list={item} rooms={data} />
            
        ))}
        </div>
        </>
    )
}