import Navbar from "./Navbar";
import { useState,useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function Header() {
  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  const headerStyle = {
    backgroundColor:scrolling?'#FFD13C':'transparent',
    marginBottom:scrolling?'':'30px',
    transition: 'background-color 0.3s ease',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    width: '100%',
    padding: '10px 0',
    color: '#fff',
  };
  const [styles,setStyles]= useState({
    backgroundColor:"transparent",
    ...headerStyle,
  })
  useEffect(()=>{
    if(location.pathname === "/" && !scrolling){
      console.log('d')
      setStyles((prevStyle) => ({
        ...prevStyle,
        backgroundColor: "transparent",
      })
      )
    }else{
      console.log('ddsfd')

      setStyles((prevStyle) => ({
        ...prevStyle,
        backgroundColor: "#FFD13C",
      })
      )
    }

  },[location.pathname,scrolling])
  return (
    <header style={styles}>
      <Navbar />
    </header>
  );
}
