import { useState } from "react";
import Button from "../../view/Button/Btn/Button";
import "./Subscription.css";
import { emailValidation,nameValidation,textOnly } from "../../utlis/validation";
import MailIcon from "../../assets/icons/mail-open-icon.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ErrorDialog from "../../view/Dialog/ErrorDialog/ErrorDialog";
import SuccessDialog from "../../view/Dialog/SuccessDialog/SuccessDialog";
export default function Subscription() {
  const [email, setEmail] = useState({
    value: "",
    valid: "",
  });
  const [name,setName] = useState({
    value: "",
    valid: "",
  })
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  /* Reset form */
  const resetForm=()=>{
    setEmail({
        value:'',
        valid:''
    });
    setName({
      value: "",
    valid: "",
    })
    setLoading(false);
    setSubmit(false)
  }
  const [succInfo,setSuccInfo] =useState({
    show: false,
    content: "",
    
  });
  const succCancel = () => {
    setSuccInfo({
      ...succInfo,
      show: false,
      content: "",
    });
  };
  const [errInfo,setErroInfo] =useState({
    show: false,
    content: "",
    
  });
  const errorCancel = () => {
    setErroInfo({
      ...errInfo,
      show: false,
      content: "",
    });
  };
  /* Form Submit */
  const onSubmitHandler = async () => {
    setSubmit(true);
    
    if(!name.valid || !email.valid){
      return;
    }
   
     

    const formData = {
      name:name.value,
      email: email.value,
    };
    if (email.valid &&name.valid) {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/subscribe`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData }),
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        setSuccInfo({
          show:true,
          content:"Congratulations! You're now subscribed to our service"
        });
        setTimeout(succCancel,5000)
        setSubmit(false);
        setLoading(false);
        resetForm();
      } else {
        if(response.status===400  &&data.status==='dublicate'){
          setErroInfo({
            ...errInfo,
            show:true,
            content:"You're already subscribed to our service. Thank you for being part of our community!"
          });
          setTimeout(errorCancel,5000);

        }else{
          setErroInfo({
            ...errInfo,
            show:true,
            content:data.err
          });
          setTimeout(errorCancel,5000);
        }
        setSubmit(false);
        setLoading(false);
        resetForm();
      }
    }else{
      return;

    }
  };


  return (
    <div className="subscription mb-4">
      <h5 className="fs-3 text-center fw-bold">Subscribe and Stay Engaged </h5>

      <form>
        <div className="input-containers">
          
        <div className="control-input">
          <div className="form-container input-1">
            <input
              type="text"
              value={name.value}
              placeholder="Enter your Name"
              onChange={(e) => {
                if(e.target.value.trim()===''){
                  setName({
                    ...name,
                    value: e.target.value.trim(),
                    valid:false
                  });

                }else{
                  setName({
                    ...name,
                    value: e.target.value,
                    valid:nameValidation(e.target.value)
                  });


                }
              }}
            />
          
          </div>
          {submit && !name.valid && (
          <span className="text-danger ms-4">Input must be at least 4 characters</span>
        )}
        </div>
        <div className="control-input">
        <div className="form-container input-2">
          <input
            type="email"
            value={email.value}
            placeholder="Enter your Email"
            onChange={(e) => {
              setEmail({
                ...email,
                value: e.target.value.trim(),
                valid: emailValidation(e.target.value.trim()),
              });
            }}
          />
          <Button
            className="secondaryBtn subscribeBtn"
            onClick={onSubmitHandler}
            type="button"
          >
            <div className="d-flex align-items-center flex-gap-half">
              {!loading && <img src={MailIcon} style={{ width: "20px" }} />}
              {loading && <FontAwesomeIcon icon={faSpinner} className="fs-5" />}
              <span className="text-nowrap fw-bold"> Subscribe Now</span>
            </div>
          </Button>
        </div>
        {submit && !email.valid && (
          <span className="text-danger ms-4">Provide Valid Email</span>
        )}
        </div>
        
        
        
        </div>
      </form>
      <ErrorDialog styles={{width:'450px'}} show={errInfo.show} content={errInfo.content} onHide={errorCancel}/>
      <SuccessDialog show={succInfo.show} content={succInfo.content} onHide={succCancel} />
    </div>
  );
}
