import Privacy from "../assets/document/projectC_privacyPolicy.pdf";
import Terms from "../assets/document/projectC_termsAndCondition.pdf";
export default function Footer() {
  return (
    <footer className="bgPrimary py-3 text-center">
      <div className="footer mx-auto"> 
        <div className="container d-flex flex-column justify-content-start justify-content-md-between flex-lg-row " >
          <div className="">
            <span className="text-white ">
              Copyright &copy; 2024 Civil Planner. All Rights Reserved. |{" "}
            </span>

            <span className="text-white mt-3 mt-md-0">
              Developed By
              <span className="secondary-text"> ProZ Solutions LLP.</span>
            </span>
          </div>
          <div className="d-flex justify-content-end text-white mt-3 mt-lg-0">
            <a href={Privacy} target="_blank" className="text-white pdf-link" >
              {" "}
              Terms & conditions{" "}
            </a>
          <span className="text-white d-block mx-2" >{"  "}/{" "}</span>
            <a href={Terms} target="_blank" className="text-white pdf-link">
            {" "}Privacy policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
