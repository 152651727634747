// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";
import Header from "./components/Header";

import Button from "./view/Button/Btn/Button";
import UploadForm from "./components/DXFFileProcess/UploadForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import ErrorPage from "./components/ErPage";
import Contact from "./components/Contact";
import Report from "./components/DXFFileProcess/ProcessingReport/ProcessingReport";
import RommDetails from "./components/DXFFileProcess/ProcessingReport/RoomDetails/RoomDetails";
import DialogModel from "./view/Dialog/Dialog";
import About from "./components/About";
import Services from "./Service";
import Home from "./components/Home";
import { Link,  Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { useEffect,useState } from "react";
import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import ReactGA from 'react-ga';

function initializeReactGA() {
  ReactGA.initialize('G-QX7Q2Q7K17');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  initializeReactGA();

  const clickHandler = () => {
    console.log("hello");
  };
  
  const [showButton, setShowButton] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };

  // Event listener to show/hide the button based on scroll position
  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    
    <Router>
        <div className="App">
          <Header />
          <DialogModel />
          <Routes>


            <Route path="/" element={<Home />} />

            <Route path="dxf/report" element={<Report />} />
          </Routes>
          <Footer />
        </div>
        <button className="top-button" onClick={scrollToTop} style={{ display: showButton ? 'block' : 'none' }}>
        <MdOutlineKeyboardDoubleArrowUp />

        </button>
      </Router>
  );
}

export default App;
