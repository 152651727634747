import { TypeAnimation } from 'react-type-animation';

const TextTypeAnimation = () => {
  return (
    <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        "Processing file. Sit tight, won't be long!",
        30000, // wait 1s before replacing "Mice" with "Hamsters"
        'Still processing file. Please wait.',
        30000,
        'Organizing your design content to facilitate in-depth analysis',
        30000,
        
      ]}
      wrapper="span"
      speed={10}
      style={{ fontSize: '1em', display: 'inline-block',color:'var(--primary-color)',fontWeight:'600' }}
      repeat={Infinity}
    />
  );
};

export default TextTypeAnimation