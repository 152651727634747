import './LayoutTopContainer.css';
import boxImg from '../../../../assets/img/box.png'
import boxImg1 from '../../../../assets/img/box-small.png'
export default function LayoutTopContainer({children}){
    return(
        <>
        <div className='layoutTopContainer'>
            <img  src={boxImg} className='box-img img-1'/>
            <img  src={boxImg1}  className='box-img img-2'/>
            {children}
        </div>
        </>
    )
}