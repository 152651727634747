import ConstructionIcon from "../../../assets/Home.svg";

export default function TopContainer(){
    return(
        <div className="container">
          <div className="w-100 row align-items-center justify-content-between p-3">
            {/* <div className="col-lg-6">
              <h1 className="text-white text-h1 font-weight-bold">
                Building the <br /> future together
              </h1>
              <span className="text-white fs-5">
                Made this as a quick task and <br />
                ended up really loving this.
              </span>
            </div> */}
            <div className="col-lg-12">
              <img
                src={ConstructionIcon}
                alt="construction"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
    )
}