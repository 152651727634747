import { Upload, message, Progress } from "antd";
import React, { useState, useRef } from "react";
import { Toast } from "primereact/toast";

import Button from "../../view/Button/Btn/Button";
import UploadIcon from "../../assets/icons/upload.svg";
import FileIcon from "../../assets/icons/file.svg";
import "./UploadForm.css";
import InfiniteProgressBar from "../../view/ProgressBar/InfiniteProgressBar/InfiniteProgressBar";
import TopContainer from "./TopContainer/TopContainer";
import Instruction from "./TopContainer/Instruction/Instruction";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import RoomIcon from "../../assets/icons/room.svg";
import ListContainer from "./ListContainer/ListContainer";
import LoaderIcon from "../../assets/gif/file-loader.gif";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import TextTypeAnimation from "../../view/Text/TypeAnimation/TypeAnimation";
import DialogModel from "../../view/Dialog/Dialog";
import WarnIcon from "../../assets/icons/warn-icon.svg";
import ErrorDialog from "../../view/Dialog/ErrorDialog/ErrorDialog";
import Privacy from '../../assets/document/projectC_privacyPolicy.pdf'
import Terms from '../../assets/document/projectC_termsAndCondition.pdf'
import { getIp } from "../../utlis/Ip";
const { Dragger } = Upload;

export default function UploadForm() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const [haveData, setHaveData] = useState(false);
  const [aknowledge, setAknowledge] = useState(false);
  const [aknErr, setAknErr] = useState(false);
  const toast = useRef(null);
  const [errInfo, setErrInfo] = useState({
    show: "",
    content: "",
  });

  const handleFileChange = (e) => {
    setProgress(true);
    const file = e.file;
    if (file) {
      // Check if the file type is DXF
      if (file.type === "application/dxf" || file.name.endsWith(".dxf")||file.type === "application/dwg" || file.name.endsWith(".dwg")) {
        if (file.size <= 20 * 1024 * 1024) {
          // Convert MB to Bytes

          setSelectedFile(file);
          setSelectedFileName(file.name); // Set the selected file name
          setTimeout(() => {
            setProgress(false);
          }, 1000);
        } else {
          message.error("File size exceeds the limit of 20MB.");
          setProgress(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "File size exceeds the limit of 20MB.",
            life: 3000,
          });
        }
      } else {
        alert("Please select a valid DXF file.");
        setProgress(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Please select a valid DXF file.",
          life: 3000,
        });
      }
    }
  };

  const handleFileUpload = async () => {
    if (!aknowledge) {
      setAknErr(true);

      return;
    }
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }
    setAknErr(false);
    setLoading(true);
    
    try {
      setLoading(true);
      const ip = await getIp()
      const formData = new FormData();
      formData.append('ip',ip);
      formData.append("dxfFile", selectedFile);

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/funtemetal-data`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 200) {
        // Handle success
        const data = await response.json();
        setLoading(false);
        setHaveData(true);
        // setItemList(splitData(data));
        if (data.statusCode === 200) {
          navigate("/dxf/report", {
            state: { data: data.data, img: data.img },
          });
        } else {
          setErrInfo({
            ...errInfo,
            show: true,
            content: data.message,
          });
          removeFileHandler();
          setTimeout(errorCancel, 5000);
        }
      } else {
        // Handle error
        console.error("File upload failed");
        setLoading(false);
        setHaveData(false);
        removeFileHandler();
        setErrInfo({
          ...errInfo,
          show: true,
          content: "File upload failed",
        });
        setTimeout(errorCancel, 5000);
      }
    } catch (error) {
      removeFileHandler();
      setErrInfo({
        ...errInfo,
        show: true,
        content: "Internal Server Error",
      });
      setTimeout(errorCancel, 5000);
      // console.error("An error occurred:", error);
      setLoading(false);
      setHaveData(false);
    }
  };
  const removeFileHandler = () => {
    setSelectedFile();
    setSelectedFileName();
    setAknowledge(false);
  };

  const splitData = (data) => {
    const arrList = [];
    data.map((list, i) => {
      if (list.status == "Data") {
        arrList.push({
          id: i,
          title: list.layoutName,
          items: [
            {
              lableName: "Number of Doors",
              count: list.numberOfDoors,
              img: RoomIcon,
            },
            {
              lableName: "Number of Windows",
              count: list.overallWindowCount,
              img: RoomIcon,
            },
            {
              lableName: "Number of Rooms",
              count: list.numberOfRooms,
              img: RoomIcon,
            },
            {
              lableName: "Number of Stairs",
              count: list.redStairsCount,
              img: RoomIcon,
            },
          ],
        });
      }
    });
    return arrList;
  };
  const addNewHandler = () => {
    setHaveData(false);
    setItemList([]);
    removeFileHandler();
  };

  const handleOk = () => {
    setAknErr(false);
  };

  const handleCancel = () => {
    setAknErr(false);
  };
  const errorCancel = () => {
    setErrInfo({
      ...errInfo,
      show: false,
      content: "",
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="bgSecondary top-cont" >
        <div className="row pt-8 mx-auto align-items-center" style={{ maxWidth: "1400px" }}>
          <div className="col-md-6">
              <div className="upload-section bg-white p-3 rounded shadow">
                <h4 className="primary-text fs-4 text-center fw-bold ">
                {!loading&& `Upload a File`}
                {loading&& `Processing a File`}
                </h4>
                {/* <div className={loading ? "d-hide" : ""}> */}

                <div className={`bg-light uploadContainer text-center `}>
                  <Dragger
                    name="file"
                    multiple={false}
                    accept=".dwg,.dxf"
                    maxCount={1}
                    className="d-inline-block px-3"
                    disabled={loading}
                    beforeUpload={() => {
                      /* update state here */
                      return false;
                    }}
                    onChange={handleFileChange}
                  >
                    {!loading && (
                      <div>
                        {!selectedFile && (
                          <img
                            src={UploadIcon}
                            alt="upload"
                            className="uploadIcon mx-auto"
                          />
                        )}
                        {selectedFileName && (
                          <img
                            src={FileIcon}
                            alt="file"
                            className="fileIcon mx-auto d-block my-2"
                          />
                        )}
                        <span className="primary-text ">
                          {selectedFileName}
                        </span>
                        {progress && (
                          <div
                            style={{ maxWidth: "200px", width: "100%" }}
                            className="mx-auto"
                          >
                            <InfiniteProgressBar
                              style={{ maxWidth: "200px", width: "100%" }}
                            />
                          </div>
                        )}

                        <span className="text-secondary d-block fs-6 my-2">
                          Upload dxf or dwg file Maximum size 20MB
                        </span>
                        <div className="d-flex flex-column align-items-center mb-3">
                          <span className="primary-text d-block fw-bold text-center">
                            Drag and Drop your file here <br /> or
                          </span>
                          {!loading && (
                            // <Upload
                            //   name="file"
                            //   multiple={false}
                            //   draggable
                            //   accept=".dxf"
                            //   maxCount={1}
                            //   beforeUpload={() => {
                            //     /* update state here */
                            //     return false;
                            //   }}
                            //   onChange={handleFileChange}
                            //   className=""
                            // >
                            <Button
                              className="secondaryBtn-border text-center mx-auto"
                              disabled={progress}
                            >
                              Choose file
                            </Button>
                            // </Upload>
                          )}
                        </div>
                      </div>
                    )}
                    {loading && (
                      <div
                        className="text-center "
                        style={{ cursor: "default" }}
                      >
                        <img src={LoaderIcon} alt="loader" />
                        <div className="d-block">
                          <TextTypeAnimation />
                        </div>
                      </div>
                    )}
                  </Dragger>
                </div>

                {/* </div> */}
                {selectedFileName && !progress && !loading && (
                  <div>
                    <div className="d-flex flex-gap-1 my-1 align-items-start">
                      <input
                        type="checkbox"
                        id="aknowledge"
                        className="mt-2 cursor-pointer"
                        onChange={(e) => {
                          setAknowledge(e.target.checked);
                          if (!e.target.checked) {
                            setAknErr(true);
                          } else {
                            setAknErr(false);
                          }
                        }}
                      />
                      <div>
                        <label htmlFor="aknowledge" className="cursor-pointer aknowledge-content">
                          I agree to the 
                           <a href={Privacy} target="_blank">
                           {' '}terms & conditions {''}
                          </a>
                           and the 
                           <a href={Terms} target="_blank">{' '}
                           Privacy policy

                           </a>
                        </label>
                        {/* {aknErr&&<span className="text-danger">you acknowledge/agree to the terms and conditions.</span>} */}
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <Button
                        className="primaryBtn mx-auto"
                        onClick={handleFileUpload}
                        disabled={loading}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>
                )}
              </div>
          </div>
          <div className="col-md-6 d-none d-md-block">
            <TopContainer />
          </div>
        </div>
      </div>
      {/* <Instruction /> */}

      {/* Restult */}

      {/* <Modal
        title="Alert Module"
        open={aknErr}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="text-danger fw-bold">
          Kindly acknowledge/agree to the terms and conditions.
        </p>
      </Modal> */}

      <DialogModel
        className="warning-dialog"
        show={aknErr}
        onHide={handleCancel}
      >
        <div className="d-flex flex-gap-half align-items-center">
          <img src={WarnIcon} alt="warn icon"/>

          <div>
            <span className="fs-5 mb-2 d-block fw-bold">Warning Message</span>

            <p>Please confirm that you acknowledge the terms and conditions.</p>
          </div>
        </div>
      </DialogModel>

      <ErrorDialog
        show={errInfo.show}
        content={errInfo.content}
        onHide={errorCancel}
      ></ErrorDialog>
    </>
  );
}
