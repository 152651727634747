import { useState, useRef, useEffect } from "react";
import { NavLink, useLocation, Link as Links, useNavigate } from "react-router-dom";
import { ReactComponent as Brand } from "../assets/icons/ProjectC.svg";
import { ReactComponent as FindLens } from "../assets/icons/FindLens.svg";
import "./Navbar.css";
import BrandLogo from "../assets/icons/ProjectC.svg";
import Button from "../view/Button/Btn/Button";
import LoginIcon from "../assets/icons/Login.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode, faCopy } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "primereact/tooltip";
import ShareIcon from "../assets/img/share-image.svg";
import { Link, animateScroll } from "react-scroll";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import {
  FacebookShareButton,
  FacebookIcon,
  EmailIcon,
  EmailShareButton,
  InstagramShareButton,
  InstagramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import DialogModel from "../view/Dialog/Dialog";

import LogoImg from "../assets/logo.svg";
const Navbar = () => {

  const [showNavbar, setShowNavbar] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const location = useLocation();
  const navigate = useNavigate()
  const iconSize = 35;
  const webLink = "https://civilplanner.proz.in/";
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleInviteClick = () => {

    setShowPopup((prevState) => {
      return !prevState;
    }); // Open the popup when "Invite Friends" is clicked
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };
  const copyInputRef = useRef(null);

  const copyText = () => {
    if (copyInputRef.current) {
      console.log(copyInputRef.current);
      copyInputRef.current.select();
      document.execCommand(webLink);
    }
  };
  const [textToCopy, setTextToCopy] = useState(""); // The text you want to copy
  const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was co
  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
  };
  const embedLink = `<iframe width="560" height="315" src={webLink} title="Civil Planer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  `;

  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section[id]");
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          setActiveLink(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const contactRef = useRef()
  const aboutRef = useRef();
  const scrollFun = (nav) => {
    navigate('/')
    setTimeout(()=>{
      console.log(nav)
      
      if(nav==='about'){
        
        aboutRef?.current?.scrollTo('about')
      }else{
        console.log('nav2',nav)
        aboutRef?.current?.scrollTo(nav)
  
      }
    },1000)
   


  }
  return (
    <>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="logo mt-0">
            <NavLink to="/" spy={true} smooth={true} duration={500} className="cursor-pointer">
              {/* <h1>Civil Planner</h1> */}
              <img src={LogoImg} style={{ width: "250px" }} className="py-1" />
            </NavLink>
          </div>

          <div className="menu-icon" onClick={handleShowNavbar}>
            {showNavbar ? (
              <AiOutlineClose style={{ color: "var(--primary-color)" }} size={25} />
            ) : (
              <RxHamburgerMenu style={{ color: "var(--primary-color)" }} size={25} />
            )}
          </div>
          <div className={`nav-elements ${showNavbar && "active"}`}>
            {location.pathname !== '/dxf/report' && <ul>
              <li className="all-center">
                <Link
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={activeLink === "home" ? "active" : ""}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={location.pathname === '/dxf/report' ? '/' : 'about'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={activeLink === "about" ? "active" : ""}
                  ref={aboutRef}

                >
                  About
                </Link>
              </li>

              <li>
                <Link
                  to={location.pathname === '/dxf/report' ? '/' : 'contact'}
                  spy={true}
                  smooth={true}
                  duration={500}
                  className={activeLink === "contact" ? "active" : ""}
                  ref={contactRef}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <button
                  onClick={handleInviteClick}
                  className=" bg-transparent border-0 text-start px-0 text-start share-btn"
                  style={{ minWidth: "100px" }}
                >
                  Invite Friends
                </button>
              </li>
            </ul>}
            {location.pathname == '/dxf/report' && <ul>
              <li className="all-center">
                <NavLink
                  to='/'
                  className={activeLink === "home" ? "active" : ""}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to='/'
                  className={activeLink === "about" ? "active" : ""}

                >
                  About
                </NavLink>
              </li>

              <li>
                <NavLink
                  to='/'
                  className={activeLink === "contact" ? "active" : ""}

                >
                  Contact Us
                </NavLink>
              </li>
              <li>
                <button
                  onClick={handleInviteClick}
                  className=" bg-transparent border-0 text-start px-0 text-start share-btn"
                  style={{ minWidth: "100px" }}
                >
                  Invite Friends
                </button>
              </li>
            </ul>}
          </div>
        </div>
      </nav>
      {/* Conditionally render the popup */}

      {showPopup && (
        <DialogModel
          header="Invite Friend"
          show={showPopup}
          onHide={handleClosePopup}
          className="share-popup"
          styles={{ overflowX: "hidden" }}
        >
          <>
            <div className="row flex-row align-items-center">
              <div className="col-md-5 text-center">
                <img
                  src={ShareIcon}
                  style={{ width: "260px", marginLeft: "-20px" }}
                />
              </div>
              <div className="col-md-7">
                <div
                  className="row flex-column mb-2 w-100"
                  style={{ overflowX: "hidden" }}
                >
                  <div className="col-md-12 my-2">
                    <div className="form-control copy-container d-flex justify-content-between w-100 align-items-center">
                      <input
                        className="bg-transparent border-0 p-1 w-100"
                        ref={copyInputRef}
                        disabled
                        value={webLink}
                        tooltip="Enter your username"
                      />
                      <CopyToClipboard
                        className="border-0 align-center cursor-pointer"
                        style={{ width: "20px" }}
                        text={webLink}
                        onCopy={onCopyText}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{
                              fontSize: "15px",
                              color: "var(--primary-color)",
                            }}
                          />
                        </span>
                      </CopyToClipboard>
                    </div>
                    {copyStatus && (
                      <p className="text-center my-2 primary-text fw-bold">
                        Copied!!!
                      </p>
                    )}
                  </div>
                  <div className=" col-md-12 share-popup-container py-2 d-flex justify-content-start flex-gap-1">
                    <CopyToClipboard
                      style={{ width: "25px" }}
                      text={embedLink}
                      onCopy={onCopyText}
                      className="me-3"
                    >
                      <span className="embed-container">
                        <button
                          className="embed-icon "
                          style={{
                            minWidth: "38px",
                            height: "10px",
                            padding: "3px",
                            minHeight: "38px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCode}
                            style={{ fontSize: "15px" }}
                          />
                        </button>
                        {/* <span className="icon-name">Embed</span> */}
                      </span>
                    </CopyToClipboard>

                    <FacebookShareButton
                      url={webLink}
                      quote={"Dummy text!"}
                      hashtag="#muo"
                    >
                      <FacebookIcon size={iconSize} className="rounded" />
                      {/* <span className="icon-name">Facebook</span> */}
                    </FacebookShareButton>

                    <EmailShareButton
                      url={webLink}
                      quote={"Dummy text!"}
                      hashtag="#muo"
                    >
                      <EmailIcon size={iconSize} className="rounded" />
                      {/* <span className="icon-name">Email</span> */}
                    </EmailShareButton>

                    <WhatsappShareButton
                      url={webLink}
                      quote={"Dummy text!"}
                      hashtag="#muo"
                    >
                      <WhatsappIcon size={iconSize} className="rounded" />
                      {/* <span className="icon-name">Whats App</span> */}
                    </WhatsappShareButton>

                    <TwitterShareButton
                      url={webLink}
                      quote="dkfsdkfj"
                      hashtag="dsfdsfsdfew"
                    >
                      <TwitterIcon size={iconSize} className="rounded" />
                      {/* <span className="icon-name">Twitter</span> */}
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            </div>
          </>
        </DialogModel>
      )}
    </>
  );
};

export default Navbar;
