import "./Button.css";
import Image from "../../../logo.svg";
export default function Button({
  className,
  style,
  children,
  label,
  onClick,
  img,
  disabled,
  type,
  innerClass,
  imgStyle
}) {
  return (
    <button
      className={` d-inline-block text-center ${className}`}
      style={style}
      onClick={onClick}
      disabled ={disabled}
      type={type?type:'button'}
    >
      <span className={`d-flex px-2 justify-content-center flex-gap-half align-items-center ${innerClass}`}>
        <span>{children}</span> 
        {img&&<img src={img} style={imgStyle} alt="icon" />}
      </span>
    </button>
  );
}
