import RoomIcon from "../../assets/icons/rooms-bg.svg";
import DoorIcon from "../../assets/icons/door-bg.svg";
import WindowIcon from "../../assets/icons/window-bg.svg";
import StairsIcon from "../../assets/icons/stairs-bg.svg";

export function dxfFileResultOptimize(data) {
  const filteredList = [];
  data.map((list, i) => {
    if (list.status === "Data") {
      filteredList.push({
        id: i,
        layoutName: list.layoutName,
        overallDimensions: list.overallDimensions,
        roomDimensions: optimizeRoomData(list.roomDimensions, list.roomImages),
        items: [
          {
            lableName: "Number of Doors",
            count: list.numberOfDoors,
            img: DoorIcon,
          },
          {
            lableName: "Number of Windows",
            count: list.overallWindowCount,
            img: WindowIcon,
          },
          {
            lableName: "Number of Rooms",
            count: list.numberOfRooms,
            img: RoomIcon,
          },
          {
            lableName: "Number of Stairs",
            count: list.redStairsCount,
            img: StairsIcon,
          },
        ],
        calculation:{...list.calculations}
      });
    }
  });

  return filteredList;
}

function optimizeRoomData(dimention, img) {
  const mergeData = {};
  for (const room in dimention) {
    if (img.hasOwnProperty(room)) {
      mergeData[room] = {
        ...dimention[room],
        image: img[room],
      };
    }
  }

  return mergeData;
}
