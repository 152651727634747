import RoomIcon from '../../../../../assets/icons/room.svg';
import './RoomCard.css';
import img from '../../../../../assets/Vector.png'
export default function RoomCard({data,count}){
    return(
    <>
        <div className="card rounded-0 my-3 border shadow-sm roomCard">
            <div className="row align-items-stretch">
                <div className="col-md-6 ">
                    <section className="bgSecondary rounded-right p-3 row left h-100 w-100 mx-auto">
                        <div className="col-sm-2">
                            <img src={RoomIcon} alt='Room' className='room-icon'/>
                        </div>
                        <div className="col-sm-10">
                            <span className='head'>Room {count+1}</span>
                            <div>
                                <div className="row flex-nowrap align-items-start mb-1">
                                    <div className="col-6 label">Width</div>
                                    <div className="col-6 content">:{Number(data.width).toFixed(2)} feet</div>
                                </div>
                                <div className="row flex-nowrap align-items-start mb-1">
                                    <div className="col-6 label">Length</div>
                                    <div className="col-6 content">:{Number(data.length).toFixed(2)}feet</div>
                                </div>
                                <div className="row flex-nowrap align-items-start mb-1">
                                    <div className="col-6 label">Area</div>
                                    <div className="col-6 content">:{Number(data.area).toFixed(2)} sq.feet</div>
                                </div>
                                {/* <div className="row flex-nowrap align-items-start mb-1">
                                    <div className="col-6 label">Surface Area</div>
                                    <div className="col-6 content">:{Number(data.surface_area).toFixed(2)} sq.feet</div>
                                </div> */}
                                
                            </div>

                        </div>
                    </section>
                </div>
                <div className="col-md-6 text-center p-2 room-view">
                    <img src={`${process.env.REACT_APP_SERVER_URL}/image/${data.image}`} />
                </div>
            </div>
        </div>
    
    </>
    )
}